<template>
  <!-- BANNER -->
  <div class="section banner-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="title-page text-capitalize">{{ title }}</div>
        </div>
      </div>
    </div>
    <div class="breadcrumb-container">
      <div class="container">
        <ol v-if="isAuto" class="breadcrumb">
          <li class="text-capitalize">
            <router-link to="/">Home</router-link>
          </li>
          <li v-for="(item, i) in breadcrumbs" :key="i">
            <router-link class="text-capitalize" :class="item.active ? 'active': null" :to="item.to">
              {{ item.text }}
            </router-link>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ['title', 'isAuto'],
  data() {
    return {
      breadcrumbs: this.crumbs(),
    }
  },
  methods: {
    crumbs() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs;
      if (this.isAuto) {
        breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
                ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                : "/" + path,
            text: (this.$route.matched[idx].meta.breadCrumb || path).replace("-", " "),
            active: idx === pathArray.length - 1,
          });
          return breadcrumbArray;
        }, [])
      } else {
        breadcrumbs = [];
      }
      return breadcrumbs;
    }
  }
}
</script>

<style scoped>

</style>